.welcome {
  background-color: #fff;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center; }
  .welcome--text {
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #4c4c4c; }

@media (min-width: 1200px) {
  .welcome--text {
    font-size: 26px; } }

.advantages {
  background-color: #fff; }
  .advantages--item {
    padding: 40px 15px;
    text-align: center; }
    .advantages--item:nth-child(even) {
      border-left: 1px solid #b3c2bb; }
    .advantages--item:nth-child(1n+3) {
      border-top: 1px solid #b3c2bb; }
  .advantages--image {
    margin-bottom: 20px;
    padding-left: 20%;
    padding-right: 20%; }
  .advantages--title {
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #4c4c4c;
    line-height: 1; }

@media (min-width: 768px) {
  .advantages--item {
    border: 0; }
    .advantages--item + .advantages--item {
      border-left: 1px solid #b3c2bb; }
    .advantages--item:nth-child(1n+3) {
      border-top: 0; } }

@media (min-width: 992px) {
  .advantages--item {
    padding: 50px 40px; } }

@media (min-width: 1200px) {
  .advantages--item {
    padding: 65px; } }

.carousel {
  max-width: 500px;
  margin: auto; }

.about-us {
  background-color: #fff; }
  .about-us--content {
    padding: 18px 5px 18px 25px; }
  .about-us--title {
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    font-size: 26px;
    color: #4c4c4c;
    line-height: 1;
    margin-bottom: 20px; }
  .about-us--description {
    line-height: 1.5; }
